import PropTypes from 'prop-types';

import { Stack } from "@mui/material";
import About from "./about";
import MyBasicDetails from "./my-basic-details";
import { useTranslation } from "react-i18next";
import { useCallback } from 'react';

const ProfileBasicLayout = ({privateView, data, isLoading, editCallback, updateData}) => {
    const { t } = useTranslation();
    const profile_locale = "profile";

    const validateAboutMe = useCallback((value) => {
        return value.trim().length > 0;
    }, []);

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                spacing={5}
            >
                <About 
                    privateView={privateView} 
                    heading={t(`${profile_locale}.basic.about_me`)} 
                    value={data?.bio}
                    updateData={data}
                    mappingValue='bio'
                    errorMessage={t(`${profile_locale}.errors.about_me_required`)} 
                    validation={validateAboutMe}
                    isLoading={isLoading}
                    editCallback={editCallback}
                />

                <MyBasicDetails 
                    privateView={privateView}
                    responseData={data}
                    isLoading={isLoading}
                    editCallback={editCallback}
                    updateData={updateData}
                />
            </Stack>
        </>
    );
}

export default ProfileBasicLayout;

ProfileBasicLayout.propType = {
    myAccount: PropTypes.bool,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    updateData: PropTypes.object
}