import { Helmet } from "react-helmet";
import HomeLayout from "../../sections/main/home/home-layout";
import ChatBoxPcLayout from "../../sections/chat/chat-box-pc-layout";

const HomePage = () => {
    return(
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>

            <HomeLayout />
        </>
    );
}

export default HomePage;