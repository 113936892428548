import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTheme } from "@emotion/react";
import { 
    Tooltip,
    Typography,
    Box
} from "@mui/material";

import { TextFieldComponent } from "../../../components/form";
import MdiShow from '../../../assets/icons/mdi-show';
import LoadingButtonComponent from "../../../components/form/loading-button-component";
import BannerLayout from "../banner-layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import MdiHide from "../../../assets/icons/mdi-hide";

import FormProviderComponent from "../../../components/form/form-provider-component";
import { validateMobileNumber, validateEmail } from '../../../utils/custom-functions';

import { useCustomLoginMutation, useGetCustomEmailMutation } from '../../../services/auth-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useAuth } from '../../../contexts/auth/auth-provider';

const LoginLayout = () => {
    const theme = useTheme();

    const authState = useAuth();

    const { t } = useTranslation();
    const login_locales = "authentication.login";

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [submitLoading, setSubmitLoading] = useState(false);

    const [userGivenDetails, setUserGivenDetails] = useState({
        userName: "",
        password: ""
    });

    const [firebaseAccessToken, setFirebaseAccessToken] = useState(null);

    const [
        getCustomEmail, 
        {
          isLoading: isGetCustomEmailLoading,
          isSuccess: isGetCustomEmailSuccess,
          isError: isGetCustomEmailError,
          data: dataGetCustomEmailToken,
          error: errorGetCustomEmailToken
        }
    ] = useGetCustomEmailMutation();
    
    const [
        customLogin, 
        {
          isLoading: isCustomLoginLoading,
          isSuccess: isCustomLoginSuccess,
          isError: isCustomLoginError,
          data: dataCustomLogin,
          error: errorCustomLogin
        }
    ] = useCustomLoginMutation();

    const formSchema = yup.object().shape({
        username: yup.string().required('Email or phone number is required').test('isValidUsername', 'Invalid email or phone number', () => {
            const isValid = IsUsernameValid();
            return isValid;
        }),
        password: yup.string().required('Password is required')
    });

    const defaultValues = {
        username: '',
        password: ''
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch
    } = formMethods;

    const values = watch();

    const IsUsernameValid = () => {
        const mobileNumValidation = validateMobileNumber(values.username);
        const emailValidation = validateEmail(values.username);

        return mobileNumValidation || emailValidation;
    }

    const onSubmit = handleSubmit(async(data) => {
        console.log(data);

        try{
            setSubmitLoading(true);

            setUserGivenDetails({
                userName: data.username,
                password: data.password
            });
        }
        catch(error){
            console.log(error);

            setSubmitLoading(false);

            setShowSnackBar({
                state: true,
                message: `${error.code}`,
                color: "red"
            });
        }
    });

    const callCustomEmailAPI = useCallback(async () => {
        if(userGivenDetails.userName !== "" && userGivenDetails.password !== ""){
          await getCustomEmail(userGivenDetails.userName);
        }
    }, [userGivenDetails]);

    //Call after temporarily stored user given details
    useEffect(() => {
        if(userGivenDetails.userName !== "" && userGivenDetails.password !== ""){
            callCustomEmailAPI();
        }
    }, [userGivenDetails]);

    const firebaseLogin = useCallback(async (userName) => {
        try{
            console.log("Firebase login requested...");
            console.log(userGivenDetails);
        
            const accessToken = await authState.login?.(userName, userGivenDetails.password);
            console.log("Firebase accessToken...");
            console.log(accessToken);
            setFirebaseAccessToken(accessToken);
        }
        catch(error){
            setSubmitLoading(false);

            console.error(error);

            setShowSnackBar({
                state: true,
                message: error.code === 'auth/invalid-credential' ? t(`${login_locales}.firebase_login_error`) : `${error.code}`,
                color: "red"
            });
        }
    }, [userGivenDetails, authState]);
    
    const callCustomLogin = useCallback(async (idToken) => {
        await customLogin(idToken);
    }, []);

    //Call after temporarily stored firebase response
    useEffect(() => {
        if(firebaseAccessToken){
            callCustomLogin(firebaseAccessToken);
        }
    }, [firebaseAccessToken]);

    //getCustomEmail success
    useEffect(() => {
        if (isGetCustomEmailSuccess && dataGetCustomEmailToken) {
            console.log(dataGetCustomEmailToken);
            firebaseLogin(dataGetCustomEmailToken.data);
        }
    }, [isGetCustomEmailSuccess, dataGetCustomEmailToken]);

    //getCustomEmail error
    useEffect(() => {
        if (isGetCustomEmailError && errorGetCustomEmailToken) {
            setSubmitLoading(false);
            console.log(errorGetCustomEmailToken);

            setShowSnackBar({
                state: true,
                message: `${errorGetCustomEmailToken.data.error.message}`,
                color: "red"
            });
        }
    }, [isGetCustomEmailError, errorGetCustomEmailToken]);

    //customLogin success
    useEffect(() => {
        if (isCustomLoginSuccess && dataCustomLogin) {
            console.log(dataCustomLogin);

            setSubmitLoading(false);
            reset();

            authState.saveLoginResponse?.(dataCustomLogin.data);

            navigate('/feed');         
        }
    }, [authState, isCustomLoginSuccess, dataCustomLogin, navigate, reset]);

    //customLogin error
    useEffect(() => {
        if (isCustomLoginError && errorCustomLogin) {
            setSubmitLoading(false);
            console.log(errorCustomLogin);

            setShowSnackBar({
                state: true,
                message: `${errorCustomLogin.data.error.message}`,
                color: "red"
            });

            // Mobile number not verified
            if(errorCustomLogin.status === 400){
                setTimeout(() => {
                    navigate('/')
                }, 1500);
            }
        }
    }, [isCustomLoginError, errorCustomLogin, navigate]);

    // Reset user given details and firebase response
    useEffect(() => {
        if(!submitLoading && userGivenDetails.userName !== "" && userGivenDetails.password !== ""){
            console.log("Reset");

            setUserGivenDetails({
                userName: "",
                password: ""
            });

            setFirebaseAccessToken(null);
        }
    }, [submitLoading, userGivenDetails]);

    const loginForm = () =>
        <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
            <Box
                sx={{ 
                    backgroundImage: `url('/assets/icons/authentication-6.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'right',
                    px: {xs: 5, sm: 10, md: 20},
                    height: {xs: '100%', md: '100vh'},
                    display: 'grid',
                    alignContent: 'center',
                }}
            >
                <Typography
                    textAlign='center'
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 34, md: 48},
                        letterSpacing: -2,
                        color: theme.palette.secondary.main
                    }}
                >
                    {t(`${login_locales}.heading`)}
                </Typography>

                <TextFieldComponent 
                    name='username'
                    label={t(`${login_locales}.username_label`)}
                    fullWidth
                />

                <TextFieldComponent 
                    name='password'
                    label={t(`${login_locales}.password_label`)}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endIcon={
                        !showPassword 
                        ? 
                        <MdiHide style={{width: '20px', color: '#637381'}}/> 
                        : 
                        <MdiShow style={{width: '20px', color: '#637381'}}/>
                    }
                    endIconCallback={() => setShowPassword((status) => !status)}
                />

                <LoadingButtonComponent 
                    text={t(`${login_locales}.action`)}
                    inLoading={submitLoading}
                    fullWidth
                    type="submit"
                />

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: '#6D6D6D',
                        mt: 3,
                        mb: 2,
                        textAlign: 'left'
                    }}
                >
                    {t(`${login_locales}.forgot_password`)}

                    <span>
                        <button 
                            style={{
                                fontFamily: 'Inter', 
                                fontWeight: 600, 
                                fontSize: 14,
                                color: theme.palette.primary.main,
                                backgroundColor: '#ffffff',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                            onClick={() => navigate('/reset-password')}
                        >
                            {t(`${login_locales}.forgot_password_action`)}
                        </button>
                    </span>
                </Typography>

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: '#6D6D6D',
                        textAlign: 'center'
                    }}
                >
                    {t(`${login_locales}.register`)}

                    <Tooltip title={t(`${login_locales}.register_tip`)}>
                        <span>
                            <button 
                                style={{
                                    fontFamily: 'Inter', 
                                    fontWeight: 600, 
                                    fontSize: 14,
                                    color: theme.palette.primary.main,
                                    backgroundColor: '#ffffff',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate('/')}
                            >
                                {t(`${login_locales}.register_action`)}
                            </button>
                        </span>
                    </Tooltip>
                </Typography>
            </Box>
        </FormProviderComponent>

    return(
        <>
            <BannerLayout 
                title={t(`${login_locales}.banner_heading`)}
                verticalImg="/assets/images/login/portrait.png"
                landscapeImg="/assets/images/login/landscape.png"
                children={loginForm()}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default LoginLayout;