import { Helmet } from "react-helmet";
import VerificationLayout from "../../sections/profile-verification/verification/verification-layout";

const VerificationPage = () => {
    return(
        <>
            <Helmet>
                <title>Profile Verification</title>
            </Helmet>

            <VerificationLayout />
        </>
    );
}

export default VerificationPage;