import { Helmet } from "react-helmet";
import AboutUsLayout from "../../sections/about/about-us-layout";

const AboutUsPage = () => {
    return(
        <>
            <Helmet>
                <title>About us</title>
            </Helmet>

            <AboutUsLayout />
        </>
    );
}

export default AboutUsPage;