import { ThemeProvider } from "@emotion/react";
import Router from "./routes/router";
import { BrowserRouter } from 'react-router-dom';
import theme from "./theme";
import {AuthProvider} from "./contexts/auth";
import { Provider } from 'react-redux';
import store from './store/store';
import ChatBoxPcLayout from "./sections/chat/chat-box-pc-layout";
import { Stack } from "@mui/material";
import { ChatProvider } from "./contexts/chat";
import { NotificationProvider } from "./contexts/notification/notification-provider";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <NotificationProvider>
            <ChatProvider>
              <ThemeProvider theme={theme}>
                  <Router />

                  <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='end'
                    sx={{
                      position: 'fixed',
                      bottom: 0,
                      right: 0,
                      zIndex: 999,
                    }}
                    spacing={2}
                  >
                    <ChatBoxPcLayout />
                  </Stack>
              </ThemeProvider>
            </ChatProvider>
          </NotificationProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
