import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";
import ProfileEditBody from "../profile-edit-body";

import { useTranslation } from "react-i18next";
import { Button, Divider, Stack, useTheme } from "@mui/material";

import PhoneIcon from "../../../assets/icons/profile-basic/phone-icon";
import CalendarMonthOutlineIcon from "../../../assets/icons/calender-month-outline-icon";
import AccountVoiceIcon from "../../../assets/icons/profile-basic/account-voice-icon";
import GenderFemaleIcon from "../../../assets/icons/profile-basic/gender-female-icon";
import RingIcon from "../../../assets/icons/profile-basic/ring-icon";
import HumanHeightIcon from "../../../assets/icons/human-height-icon";
import HandsPrayIcon from "../../../assets/icons/hands-pray-icon";
import BookAccountOutlineIcon from "../../../assets/icons/book-account-outline-icon";
import AccountStarIcon from "../../../assets/icons/profile-basic/account-star-icon";
import CalendarStarIcon from "../../../assets/icons/profile-basic/calender-star-icon";
import TempleHinduOutlineIcon from "../../../assets/icons/profile-basic/temple-hindu-outline-icon";
import FoodOutlineIcon from "../../../assets/icons/profile-basic/food-outline-icon";
import BeerOutlineIcon from "../../../assets/icons/profile-basic/beer-outline-icon";
import CigarIcon from "../../../assets/icons/profile-basic/cigar-icon";
import MapMarkerOutlineIcon from "../../../assets/icons/map-marker-outline-icon";
import HomeIcon from "../../../assets/icons/home-icon";
import CityVariantOutlineIcon from "../../../assets/icons/profile-professional/city-variant-outline-icon";
import FlagIcon from "../../../assets/icons/profile-professional/flag-icon";
import BookMarkerOutlineIcon from "../../../assets/icons/profile-professional/book-marker-outline-icon";
import { LoadingButtonComponent, RadioButton, SelectionComponent, TextFieldComponent } from '../../../components/form';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useEffect, useState } from 'react';
import DatePickerComponent from '../../../components/form/date-picker-component';

import {
    genderList,
    physicalStatusList,
    starList,
    raasiList,
    eatingHabitList,
    drinkingHabitList,
    smokingHabitList,
    bodyTypeList
} from '../../../assets/data/profile';
import { useGetSpecificMasterDataQuery, useGetSubCasteWithCasteIDMutation } from '../../../services/master-data-api';
import FormProviderComponent from '../../../components/form/form-provider-component';
import { useAuth } from '../../../contexts/auth/auth-provider';
import { countries } from '../../../assets/data/countries';
import TimePickerComponent from '../../../components/form/time-picker-component';
import MingCuteTimeLine from '../../../assets/icons/profile-basic/ming-cute-time-line-icon';
import HeightIcon from '../../../assets/icons/profile-basic/height-icon';
import BodyBoldIcon from '../../../assets/icons/profile-basic/body-bold-icon';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';

const MyBasicDetails = ({privateView, responseData, isLoading, editCallback, updateData}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile.basic";
    const sign_up_locale = "authentication.sign_up";

    const auth = useAuth();

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [religionList, setReligionList] = useState([]);
    const {isLoading: isLoadingReligion, isSuccess: isSuccessReligion, data: dataReligion, isError: isErrorReligion, error: errorReligion} = useGetSpecificMasterDataQuery('religion');
    useEffect(() => {
        if(isSuccessReligion && dataReligion){
            console.log("Religion master data...");
            console.log(dataReligion);

            setReligionList(getListData(dataReligion.data));
        }
        else if(isErrorReligion && errorReligion){
            console.log("Religion master data error...");
            console.log(errorReligion);

            setReligionList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessReligion, dataReligion, isErrorReligion, errorReligion]);

    const [subCastList, setSubCastList] = useState([]);
    const [getSubCasteList, {isLoading: isLoadingSubCasteList, isSuccess: isSuccessSubCasteList, data: dataSubCasteList, isError: isErrorSubCasteList, error: errorSubCasteList}] = useGetSubCasteWithCasteIDMutation();
    useEffect(() => {
        if(isSuccessSubCasteList && dataSubCasteList){
            console.log("Sub caste master data...");
            console.log(dataSubCasteList);

            setSubCastList(getListData(dataSubCasteList.data));

            // if(dataSubCasteList.data.length === 0){
            //     setShowSnackBar({
            //         state: true,
            //         message: t(`${profile_locale}.no_sub_caste_available`),
            //         color: "blue"
            //     });
            // }
        }
        else if(isErrorSubCasteList && errorSubCasteList){
            console.log("Sub caste master data error...");
            console.log(errorSubCasteList);

            setSubCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessSubCasteList, dataSubCasteList, isErrorSubCasteList, errorSubCasteList]);

    const [castList, setCastList] = useState([]);
    const {isLoading: isLoadingCaste, isSuccess: isSuccessCaste, data: dataCaste, isError: isErrorCaste, error: errorCaste} = useGetSpecificMasterDataQuery('caste');
    useEffect(() => {
        if(isSuccessCaste && dataCaste){
            console.log("Caste master data...");
            console.log(dataCaste);

            setCastList(getListData(dataCaste.data));
        }
        else if(isErrorCaste && errorCaste){
            console.log("Caste master data error...");
            console.log(errorCaste);

            setCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessCaste, dataCaste, isErrorCaste, errorCaste]);

    const [motherTongueList, setMotherTongueList] = useState([]);
    const {isLoading: isLoadingMotherTongue, isSuccess: isSuccessMotherTongue, data: dataMotherTongue, isError: isErrorMotherTongue, error: errorMotherTongue} = useGetSpecificMasterDataQuery('mother-tongue');
    useEffect(() => {
        if(isSuccessMotherTongue && dataMotherTongue){
            console.log("Mother tongue master data...");
            console.log(dataMotherTongue);

            setMotherTongueList(getListData(dataMotherTongue.data));
        }
        else if(isErrorMotherTongue && errorMotherTongue){
            console.log("Mother tongue master data error...");
            console.log(errorMotherTongue);

            setMotherTongueList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMotherTongue, dataMotherTongue, isErrorMotherTongue, errorMotherTongue]);

    const [doshamList, setDoshamList] = useState([]);
    const {isLoading: isLoadingDosham, isSuccess: isSuccessDosham, data: dataDosham, isError: isErrorDosham, error: errorDosham} = useGetSpecificMasterDataQuery('dosha');
    useEffect(() => {
        if(isSuccessDosham && dataDosham){
            console.log("Dosham master data...");
            console.log(dataDosham);

            setDoshamList(getListData(dataDosham.data));
        }
        else if(isErrorDosham && errorDosham){
            console.log("Dosham master data error...");
            console.log(errorDosham);

            setDoshamList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessDosham, dataDosham, isErrorDosham, errorDosham]);

    const [maritalList, setMaritalList] = useState([]);
    const {isLoading: isLoadingMaritalStatus, isSuccess: isSuccessMaritalStatus, data: dataMaritalStatus, isError: isErrorMaritalStatus, error: errorMaritalStatus} = useGetSpecificMasterDataQuery('marital-status');
    useEffect(() => {
        if(isSuccessMaritalStatus && dataMaritalStatus){
            console.log("Marital status master data...");
            console.log(dataMaritalStatus);

            setMaritalList(getListData(dataMaritalStatus.data));
        }
        else if(isErrorMaritalStatus && errorMaritalStatus){
            console.log("Marital status master data error...");
            console.log(errorMaritalStatus);

            setMaritalList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMaritalStatus, dataMaritalStatus, isErrorMaritalStatus, errorMaritalStatus]);

    const [residenceTypeList, setResidenceTypeList] = useState([]);
    const {isLoading: isLoadingResidenceType, isSuccess: isSuccessResidenceType, data: dataResidenceType, isError: isErrorResidenceType, error: errorResidenceType} = useGetSpecificMasterDataQuery('residence-status');
    useEffect(() => {
        if(isSuccessResidenceType && dataResidenceType){
            console.log("Residence type master data...");
            console.log(dataResidenceType);

            setResidenceTypeList(getListData(dataResidenceType.data));
        }
        else if(isErrorResidenceType && errorResidenceType){
            console.log("Residence type master data error...");
            console.log(errorResidenceType);

            setResidenceTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessResidenceType, dataResidenceType, isErrorResidenceType, errorResidenceType]);

    const formSchema = yup.object().shape({
        gender: yup.string().notRequired(),
        dateOfBirth: yup.string().required(t(`${sign_up_locale}.errors.dob_is_required`)),
        motherTongue: yup.string().required(t(`${sign_up_locale}.errors.mother_tongue_is_required`)),
        gender: yup.string().required(t(`${sign_up_locale}.errors.gender_is_required`)),
        height: yup.number().required(t(`${sign_up_locale}.errors.height_is_required`)).min(100).max(250).test('isValidHeight', t(`${sign_up_locale}.errors.invalid_height`), () => {
            const isValid = Number.isInteger(formValue.height);
            return isValid;
        }),
        maritalStatus: yup.string().required(t(`${sign_up_locale}.errors.marital_status_is_required`)),
        physicalStatus: yup.string().required(t(`${sign_up_locale}.errors.physical_status_required`)),
        bodyType: yup.string().required(t(`${sign_up_locale}.errors.body_type_required`)),
        religion: yup.string().required(t(`${sign_up_locale}.errors.religion_is_required`)),
        caste: yup.string().notRequired(),
        subCaste: yup.string().notRequired(),
        rashi: yup.string().notRequired(),
        star: yup.string().notRequired(),
        dosham: yup.string().required(t(`${sign_up_locale}.errors.dosham_is_required`)),
        birthCountry: yup.string().notRequired(),
        countryOfLiving: yup.string().required(t(`${sign_up_locale}.errors.country_of_living_is_required`)),
        city: yup.string().required(t(`${sign_up_locale}.errors.birth_place_is_required`)),
        hometown: yup.string().notRequired(),
        citizenship: yup.string().required(t(`${sign_up_locale}.errors.citizenship_is_required`)),
        residenceStatus: yup.string().required(t(`${sign_up_locale}.errors.residential_status_is_required`)),
        eatingHabits: yup.string().notRequired(),
        drinkingHabits: yup.string().notRequired(),
        smokingHabits: yup.string().notRequired(),
        timeOfBirth: yup.string().required(t(`${sign_up_locale}.errors.birth_time_is_required`))
    });

    const defaultValues = {
        contact: "",
        dateOfBirth: null,
        motherTongue: "",
        gender: "",
        height: 0,
        maritalStatus: "",
        physicalStatus: "",
        bodyType: "",
        religion: "",
        caste: "",
        subCaste: "",
        rashi: "",
        star: "",
        dosham: "",
        birthCountry: "",
        countryOfLiving: "",
        city: "",
        hometown: "",
        citizenship: "",
        residenceStatus: "",
        eatingHabits: "",
        drinkingHabits: "",
        smokingHabits: "",
        timeOfBirth: "",
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const [data, setData] = useState([]);

    const setTimeOfBirth = useCallback((value) => {
        if(!value){
            return null;
        }

        const splitTimer = value.split(':');

        // This date is a dummy date to pass the time as an allowed format
        return "2022-04-17T".concat(splitTimer[0]).concat(":").concat(splitTimer[1]); // 2022-04-17T15:30
    }, []);

    const getCastIDFromName = useCallback((name) => {
        let id = "";

        for(let i = 0; i < castList.length; i++){
            if(castList[i].value === name){
                id = castList[i].id;
                break;
            }
        }

        return id;
    }, [castList]);

    useEffect(() => {
        if(castList.length > 0 && responseData && responseData.caste){
            getSubCasteList({id: getCastIDFromName(responseData.caste)});
        }
    }, [responseData, castList]);

    const setFormValuesWithData = useCallback(() => {
        console.log(">>>>>");
        console.log(responseData)
        setFormValue("dateOfBirth", responseData.dateOfBirth);
        setFormValue("timeOfBirth", setTimeOfBirth(responseData.timeOfBirth));
        setFormValue("motherTongue", responseData.motherTongue);
        setFormValue("gender", responseData.gender);
        setFormValue("height", responseData.height);
        setFormValue("maritalStatus", responseData.maritalStatus);
        setFormValue("physicalStatus", responseData.physicalStatus);
        setFormValue("bodyType", responseData.bodyType);
        setFormValue("religion", responseData.religion);
        setFormValue("caste", responseData.caste);
        setFormValue("subCaste", responseData.subCaste);
        setFormValue("rashi", responseData.rashi);
        setFormValue("star", responseData.star);
        setFormValue("dosham", responseData.dosham);
        setFormValue("birthCountry", responseData.birthCountry);
        setFormValue("countryOfLiving", responseData.countryOfLiving);
        setFormValue("city", responseData.city);
        setFormValue("hometown", responseData.hometown);
        setFormValue("citizenship", responseData.citizenship);
        setFormValue("residenceStatus", responseData.residenceStatus);
        setFormValue("eatingHabits", responseData.eatingHabits);
        setFormValue("drinkingHabits", responseData.drinkingHabits);
        setFormValue("smokingHabits", responseData.smokingHabits);
    }, [responseData]);

    useEffect(() => {
        if(responseData){
            setData(
                [
                    {label: t(`${profile_locale}.contact`), value: auth?.currentUser?.mobileNumber, restrictedText: true, icon: <PhoneIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.dob`), value: responseData.dateOfBirth, icon: <CalendarMonthOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.timeOfBirth`), value: responseData.timeOfBirth, icon: <MingCuteTimeLine style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.birth_city`), value: responseData.city, icon: <HomeIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.mother_tongue`), value: responseData.motherTongue, icon: <AccountVoiceIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.gender`), value: responseData.gender, icon: <GenderFemaleIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.height`), value: `${responseData.height} cm`, icon: <HeightIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.marital_status`), value: responseData.maritalStatus, icon: <RingIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.physical_appearance`), value: responseData.physicalStatus, icon: <HumanHeightIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.body_type`), value: responseData.bodyType, icon: <BodyBoldIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.religion`), value: responseData.religion, icon: <HandsPrayIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.caste`), value: responseData.caste, icon: <BookAccountOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='caste' label={t(`${profile_locale}.caste`)} list={castList} />},
                    {label: t(`${profile_locale}.sub_caste`), value: responseData.caste ? (responseData.subCaste || subCastList.length > 0) ? responseData.subCaste : t(`${profile_locale}.no_sub_caste_available`) : t(`${profile_locale}.caste_required`), icon: <BookAccountOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='subCaste' label={t(`${profile_locale}.sub_caste`)} list={subCastList} />},
                    {label: t(`${profile_locale}.rashi`), value: responseData.rashi, icon: <AccountStarIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='rashi' label={t(`${profile_locale}.rashi`)} list={raasiList} /> },
                    {label: t(`${profile_locale}.star`), value: responseData.star, icon: <CalendarStarIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='star' label={t(`${profile_locale}.star`)} list={starList} /> },
                    {label: t(`${profile_locale}.dosham`), value: responseData.dosham, icon: <TempleHinduOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.birth_country`), value: responseData.birthCountry, icon: <MapMarkerOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='birthCountry' label={t(`${profile_locale}.birth_country`)} list={countries} />},
                    {label: t(`${profile_locale}.country_of_living`), value: responseData.countryOfLiving, icon: <MapMarkerOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.home_town`), value: responseData.hometown, icon: <CityVariantOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='hometown' label={t(`${profile_locale}.home_town`)} /> },
                    {label: t(`${profile_locale}.citizenship`), value: responseData.citizenship, icon: <FlagIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.residential_status`), value: responseData.residenceStatus, icon: <BookMarkerOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.eating_habit`), value: responseData.eatingHabits, icon: <FoodOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='eatingHabits' label={t(`${profile_locale}.eating_habit`)} list={eatingHabitList} /> },
                    {label: t(`${profile_locale}.drinking_habit`), value: responseData.drinkingHabits, icon: <BeerOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='drinkingHabits' label={t(`${profile_locale}.drinking_habit`)} list={drinkingHabitList} /> },
                    {label: t(`${profile_locale}.smoking_habit`), value: responseData.smokingHabits, icon: <CigarIcon style={{color: theme.palette.primary.main}}/>, editChildren: <SelectionComponent name='smokingHabits' label={t(`${profile_locale}.smoking_habit`)} list={smokingHabitList} /> }
                ]
            );

            setFormValuesWithData();
        }
    }, [responseData, auth]);

    const [openEditSingleItemBox, setOpenEditSingleItemBox] = useState({
        title: '',
        children: <></>,
        open: false,
        onSuccess: () => {}
    });

    const [openEditAllBox, setOpenEditAllBox] = useState({
        title: '',
        open: false,
        onSuccess: () => {}
    });

    const openSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({
            title: data.label,
            children: data.editChildren,
            open: true
        });
    }, []);

    const closeSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const openEditAllModal = useCallback((heading) => {
        setOpenEditAllBox({
            title: heading,
            open: true,
            onSuccess: () => {}
        });
    }, []);

    const closeEditAllModal = useCallback((data) => {
        getSubCasteList({id: getCastIDFromName(responseData.caste)});

        setFormValue("subCaste", responseData.subCaste);

        setOpenEditAllBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const convertDob = useCallback((dob) => {
        const inputDate = new Date(dob);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }, []);

    const convertBirthTime = useCallback((birthTime) => {
        let d = new Date(birthTime);
        let dateText = d.toTimeString();

        return dateText.split(' ')[0];
    }, []);

    const modifyRequestObj = useCallback((newData) => {
        const obj = {};
        
        for (var updateDataKey in updateData) {
            if (updateData.hasOwnProperty(updateDataKey)) {
                console.log(updateDataKey + " -----> NOT MODIFIED ----->  " + updateData[updateDataKey]);
                obj[updateDataKey] = updateData[updateDataKey];

                for (var newDataKey in newData){
                    if (newData.hasOwnProperty(newDataKey)){
                        if(updateDataKey !== newDataKey){
                            continue;
                        }
                    
                        if(updateData[updateDataKey] !== newData[newDataKey]){
                            console.log(newDataKey + " -----> MODIFIED ----->  " + newData[newDataKey]);
                            obj[updateDataKey] = newData[newDataKey];
                        }
                    }
                }
            }
        }

        return obj;
    }, [updateData]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            setOpenEditAllBox({open: false});
            setOpenEditSingleItemBox({open: false});

            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = handleSubmit(async(data) => {
        const requestData = modifyRequestObj(data);

        if(updateData.dateOfBirth !== requestData.dateOfBirth){
            requestData.dateOfBirth = convertDob(requestData.dateOfBirth);
        }

        if(updateData.timeOfBirth !== requestData.timeOfBirth){
            requestData.timeOfBirth = convertBirthTime(requestData.timeOfBirth);
        }

        console.log(requestData);
        
        await updateProfile(requestData);
    });

    const changeCaste = useCallback((newCasteName) => {
        setFormValue("subCaste", null);
        getSubCasteList({id: getCastIDFromName(newCasteName)});
    }, [castList]);

    return (
        <>
            <ProfileEditHeader 
                heading={t(`${profile_locale}.my_basic_details`)} 
                privateView={privateView}
                openEditAllCallback={openEditAllModal}
            />

            <ProfileEditBody 
                dataList={data} 
                privateView={privateView} 
                callback={openSingleEditItemModal}
                isLoading={isLoading}
            />

            <DialogBox 
                title={openEditSingleItemBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        {openEditSingleItemBox.children}

                        <Divider sx={{mt: 8, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeSingleEditItemModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditSingleItemBox.open} 
                onClose={closeSingleEditItemModal}
                customFooter
            />

            <DialogBox 
                title={openEditAllBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        <Stack
                            direction='column'
                            spacing={2}
                        >
                            <DatePickerComponent 
                                label={t(`${profile_locale}.dob`)}
                                name="dateOfBirth"
                                minDate={new Date().setFullYear(new Date().getFullYear() - 75)}
                                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                                required
                            />

                            <TimePickerComponent name="timeOfBirth" required label={t(`${profile_locale}.timeOfBirth`)}/>

                            <TextFieldComponent name='city' required label={t(`${profile_locale}.birth_city`)}/>

                            <SelectionComponent name='motherTongue' required label={t(`${profile_locale}.mother_tongue`)} list={motherTongueList} />

                            <SelectionComponent name='gender' required label={t(`${profile_locale}.gender`)} list={genderList} />

                            <TextFieldComponent name='height' required label={t(`${profile_locale}.height`)} startUnit={t(`${sign_up_locale}.step2.form.height_unit`)} type='number'/>

                            <SelectionComponent name='maritalStatus' required label={t(`${profile_locale}.marital_status`)} list={maritalList} />

                            <SelectionComponent name='physicalStatus' required label={t(`${profile_locale}.physical_appearance`)} list={physicalStatusList} />

                            <RadioButton 
                                label={t(`${sign_up_locale}.step2.form.body_type_label`)}
                                name='bodyType'
                                list={bodyTypeList}
                                selectedValue={formValue.bodyType}
                                sx={{my: 1}}
                                required
                            />

                            <SelectionComponent name='religion' required label={t(`${profile_locale}.religion`)} list={religionList} />

                            <SelectionComponent name='caste' label={t(`${profile_locale}.caste`)} list={castList} onChange={changeCaste}/>

                            <SelectionComponent name='subCaste' label={t(`${profile_locale}.sub_caste`)} list={subCastList} disabled={!isSuccessSubCasteList || subCastList.length === 0}/>

                            <SelectionComponent name='rashi' label={t(`${profile_locale}.rashi`)} list={raasiList} />

                            <SelectionComponent name='star' label={t(`${profile_locale}.star`)} list={starList} />

                            <SelectionComponent name='dosham' required label={t(`${profile_locale}.dosham`)} list={doshamList} />

                            <SelectionComponent name='birthCountry' label={t(`${profile_locale}.birth_country`)} list={countries} />

                            <SelectionComponent name='countryOfLiving' required label={t(`${profile_locale}.country_of_living`)} list={countries}/>

                            <TextFieldComponent name='hometown' label={t(`${profile_locale}.home_town`)}/>

                            <SelectionComponent name='citizenship' required label={t(`${profile_locale}.citizenship`)} list={countries}/>

                            <SelectionComponent name='residenceStatus' required label={t(`${profile_locale}.residential_status`)} list={residenceTypeList}/>

                            <SelectionComponent name='eatingHabits' label={t(`${profile_locale}.eating_habit`)} list={eatingHabitList} />

                            <SelectionComponent name='drinkingHabits' label={t(`${profile_locale}.drinking_habit`)} list={drinkingHabitList} />

                            <SelectionComponent name='smokingHabits' label={t(`${profile_locale}.smoking_habit`)} list={smokingHabitList} />
                        </Stack>

                        <Divider sx={{mt: 8, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeEditAllModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditAllBox.open} 
                onClose={closeEditAllModal}
                customFooter
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default MyBasicDetails;

MyBasicDetails.propType = {
    privateView: PropTypes.bool,
    responseData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    updateData: PropTypes.object
}