import { Helmet } from "react-helmet";
import ProfileFeedLayout from '../../sections/profile-general/profile-feed/profile-feed-layout';

const ProfileFeedPage = () => {
    return(
        <>
            <Helmet>
                <title>Profile Feed</title>
            </Helmet>

            <ProfileFeedLayout />
        </>
    );
}

export default ProfileFeedPage;