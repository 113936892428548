import { Helmet } from "react-helmet";
import ProfileInterestsLayout from "../../sections/profile-general/profile-interests/profile-interests-layout";

const ProfileInterestsPage = () => {
    return(
        <>
            <Helmet>
                <title>Profile Interests</title>
            </Helmet>

            <ProfileInterestsLayout />
        </>
    );
}

export default ProfileInterestsPage;