import PropTypes from 'prop-types';

import { 
    Box,
    Card,
    Grid,
    Link,
    Stack,
    Typography
} from "@mui/material";

import CheckDecagramIcon from '../../../assets/icons/check-decagram-icon';
import CalendarMonthOutlineIcon from '../../../assets/icons/calender-month-outline-icon';
import HumanHeightIcon from '../../../assets/icons/human-height-icon';
import HandsPrayIcon from '../../../assets/icons/hands-pray-icon';
import SchoolOutlineIcon from '../../../assets/icons/school-outline-icon';
import BriefcaseOutlineIcon from '../../../assets/icons/briefcase-outline-icon';
import MapMarkerOutlineIcon from '../../../assets/icons/map-marker-outline-icon';
import CheckIcon from '../../../assets/icons/check-icon';
import CancelIcon from '../../../assets/icons/cancel-icon';

import LoadingButtonComponent from '../../../components/form/loading-button-component';

import { useTranslation } from 'react-i18next';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useEffect, useState } from 'react';
import BookAccountOutlineIcon from '../../../assets/icons/book-account-outline-icon';
import ChatOutlineIcon from '../../../assets/icons/chat-outline-icon';
import { useNavigate } from 'react-router-dom';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useSendInterestMutation } from '../../../services/interest-api';
import { useBlockMutation, useUnblockMutation } from '../../../services/block-api';
import { useChat } from '../../../contexts/chat/chat-provider';
import { useGetUserInboxDetailsMutation } from '../../../services/chat-api';
import ChatPersonal from '../../chat/chat-personal';

const ProfileSendInterestsCard = ({data, filterType, successCallback}) => {
    const { t } = useTranslation();
    const profile_interests_locale = "profile_interests";

    const {
        userId,
        profileImages,
        firstName,
        memberId,
        verified,
        age,
        height,
        religion,
        education,
        occupation,
        countryOfLiving,
        caste,
        blocked
    } = data;

    const iconStyle = {width: '20px', height: '20px', color: '#6D6D6D'};

    const [openDialog, setOpenDialog] = useState({
        title: "",
        message: "",
        open: false,
        close: null,
        successCallback: null
    });

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const navigate = useNavigate();
    const useChatContext = useChat();

    const userOtherDetail = (customIcon, value, needBullet=true) => <Stack direction='row' alignItems='center' spacing={1}>
        {customIcon}
        
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: 14,
                color: '#6D6D6D'
            }}
        >
            {value ? value : t('profile_feed.unknown')}
        </Typography>
    </Stack>


    const getPrimaryButtonText = () => {
        if(filterType === 'PENDING' || filterType === 'ACCEPTED'){
            return t(`${profile_interests_locale}.actions.view_profile`)
        }

        return t(`${profile_interests_locale}.actions.send_interest`);
    }

    const getPrimaryButtonWidth = () => {
        if(filterType === 'PENDING' || filterType === 'ACCEPTED'){
            return '125px';
        }

        return '170px';
    }

    const getPrimaryButtonIcon = () => {
        if(filterType === 'PENDING' || filterType === 'ACCEPTED'){
            return <></>;
        }

        return <CheckIcon />;
    }

    const getPrimaryButtonAction = () => {
        if(filterType === 'PENDING' || filterType === 'ACCEPTED'){
            return requestViewProfile();
        }

        return requestSendInterest();
    }

    const getSecondaryButtonText = () => {
        if(filterType === 'ACCEPTED'){
            return t(`${profile_interests_locale}.actions.message`)
        }
        
        if(blocked){
            return t(`${profile_interests_locale}.actions.unblock`);
        }

        return t(`${profile_interests_locale}.actions.block`);
    }

    const getSecondaryButtonIcon = () => {
        if(filterType === 'ACCEPTED'){
            return <ChatOutlineIcon />;
        }

        return <CancelIcon />;
    }

    const getSecondaryButtonAction = () => {
        if(filterType === 'ACCEPTED'){
            return requestMessage();
        }

        if(blocked){
            return requestUnblockProfile();
        }

        return requestBlock();
    }



    const [
        sendInterest,
        {
            isLoading: isLoadingSendInterest,
            isSuccess: isSuccessSendInterest, 
            isError: isErrorSendInterest, 
            error: errorSendInterest
        }
    ] = useSendInterestMutation();

    useEffect(() => {
        if(isSuccessSendInterest){
            setShowSnackBar({
                state: true,
                message: t(`${profile_interests_locale}.send_interest.sent`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessSendInterest]);

    useEffect(() => {
        if(isErrorSendInterest && errorSendInterest){
            console.log(errorSendInterest);

            setShowSnackBar({
                state: true,
                message: `${errorSendInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorSendInterest, errorSendInterest]);

    const requestSendInterest = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_interests_locale}.send_interest.dialog_title`),
            message: t(`${profile_interests_locale}.send_interest.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmSendInterest
        });
    }, [memberId]);

    const confirmSendInterest = useCallback(async () => {
        setOpenDialog(false);
        await sendInterest(memberId);
    }, [memberId]);



    const requestViewProfile = useCallback(() => {
        navigate(`/profile/${memberId}`);
    }, [memberId]);



    const [
        getUserInboxDetails,
        {
            isLoading: isLoadingAllDirectChats,
            isSuccess: isSuccessAllDirectChats,
            data: dataAllDirectChats,
            isError: isErrorAllDirectChats,
            error: errorAllDirectChats
        }
    ] = useGetUserInboxDetailsMutation();

    const requestMessage = useCallback(async () => {
        getUserInboxDetails({userID: userId});
    }, [userId]);

    useEffect(() => {
        if (isSuccessAllDirectChats) {
            console.log("user chat inbox data...");
            console.log(dataAllDirectChats); 

            useChatContext.setActiveUserChat(dataAllDirectChats.data);
            useChatContext.setChatBoxBody(<ChatPersonal />);

            if(window.innerWidth < 800){
                navigate('/chat');
            }
            else{
                useChatContext.openChatBox(true);
            }
        }
    }, [isSuccessAllDirectChats, dataAllDirectChats]);

    useEffect(() => {
        if(isErrorAllDirectChats && errorAllDirectChats){
            console.log(errorAllDirectChats);

            setShowSnackBar({
                state: true,
                message: `${errorAllDirectChats.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAllDirectChats, errorAllDirectChats]);



    const [
        blockUser,
        {
            isLoading: isLoadingBlockInterest,
            isSuccess: isSuccessBlockInterest, 
            isError: isErrorBlockInterest, 
            error: errorBlockInterest
        }
    ] = useBlockMutation();

    useEffect(() => {
        if(isSuccessBlockInterest){
            setShowSnackBar({
                state: true,
                message: t(`${profile_interests_locale}.block.blocked`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessBlockInterest]);

    useEffect(() => {
        if(isErrorBlockInterest && errorBlockInterest){
            console.log(errorBlockInterest);

            setShowSnackBar({
                state: true,
                message: `${errorBlockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorBlockInterest, errorBlockInterest]);

    const requestBlock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_interests_locale}.block.dialog_title`),
            message: t(`${profile_interests_locale}.block.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmBlock
        });
    }, [userId]);

    const confirmBlock = useCallback(async () => {
        setOpenDialog(false);
        await blockUser(userId);
    }, [userId]);

    const [
        unblockUser,
        {
            isLoading: isLoadingUnblock,
            isSuccess: isSuccessUnblock, 
            isError: isErrorUnblock, 
            error: errorUnblock
        }
    ] = useUnblockMutation();

    useEffect(() => {
        if(isSuccessUnblock){
            setShowSnackBar({
                state: true,
                message: t(`${profile_interests_locale}.unblock.unblocked`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessUnblock]);

    useEffect(() => {
        if(isErrorUnblock && errorUnblock){
            console.log(errorUnblock);

            setShowSnackBar({
                state: true,
                message: `${errorUnblock.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUnblock, errorUnblock]);

    const requestUnblockProfile = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_interests_locale}.unblock.dialog_title`),
            message: t(`${profile_interests_locale}.unblock.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmUnblockProfile
        });
    }, [userId]);

    const confirmUnblockProfile = useCallback(async () => {
        setOpenDialog(false);
        await unblockUser(userId);
    }, [userId]);



    return (
        <>
            <Card 
                sx={{
                    width: '100%', 
                    my: 1,
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)'
                }}
            >
                <Grid
                    xs
                    container
                    direction={{xs: 'column', md: 'row'}}
                    p={2}
                >
                    <Stack alignItems='center'>
                        <Box 
                            sx={{
                                width: {xs: '300px', md: '205px'}, 
                                height: {xs: '300px', md: '205px'}
                            }}
                        >
                            <img 
                                src={profileImages.length > 0 ? profileImages[0]: '/assets/images/profile/profile.jpg'} 
                                alt={firstName} 
                                style={{ 
                                    width: '100%', 
                                    height: '100%', 
                                    borderRadius: '4px'
                                }}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        display='flex'
                        direction='column'
                        justifyContent='start'
                        spacing={0.5}
                        sx={{
                            pl: {xs: 0, md: 2},
                            pt: {xs: 1, md: 0}
                        }}
                    >
                        <Link href={`/profile/${memberId}`} underline='none'>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#121212'
                                }}
                            >
                                {firstName} <CheckDecagramIcon style={{color: '#00AAC4', marginBottom: -4, display: verified ? 'inline-block' : 'none'}}/>
                            </Typography>
                        </Link>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: '#6D6D6D'
                            }}
                        >
                            {memberId}
                        </Typography>

                        <Stack 
                            direction='row' 
                            spacing={3} 
                            sx={{
                                pb: 2
                            }} 
                            useFlexGap 
                            flexWrap="wrap"
                            gap={1}
                        >
                            {userOtherDetail(<CalendarMonthOutlineIcon style={iconStyle}/>, age, false)}
                            {userOtherDetail(<HumanHeightIcon style={iconStyle}/>, height)}
                            {userOtherDetail(<HandsPrayIcon style={iconStyle}/>, religion)}
                            {userOtherDetail(<SchoolOutlineIcon style={iconStyle}/>, education)}
                            {userOtherDetail(<BriefcaseOutlineIcon style={iconStyle}/>, occupation)}
                            {userOtherDetail(<MapMarkerOutlineIcon style={iconStyle}/>, countryOfLiving)}
                            {userOtherDetail(<BookAccountOutlineIcon style={iconStyle}/>, caste)}
                        </Stack>

                        <Stack 
                            direction='row' 
                            alignItems='flex-end' 
                            justifyContent={{xs: 'center', md: 'start'}} 
                            spacing={2} 
                            sx={{
                                height: '100%'
                            }}
                        >
                            <LoadingButtonComponent 
                                text={getPrimaryButtonText()}
                                onClick={() => getPrimaryButtonAction()}
                                inLoading={isLoadingSendInterest}
                                startIcon={getPrimaryButtonIcon()}
                                sx={{
                                    width: {xs: '100%', md: getPrimaryButtonWidth()},
                                    height: '45px'
                                }}
                                textSx={{
                                    fontSize: {xs: 11, md: 14},
                                    textTransform: 'capitalize'
                                }}
                            />

                            {
                                filterType === 'PENDING'
                                ?
                                <></>
                                :
                                <LoadingButtonComponent 
                                    text={getSecondaryButtonText()}
                                    onClick={() => getSecondaryButtonAction()}
                                    startIcon={getSecondaryButtonIcon()}
                                    inLoading={isLoadingBlockInterest || isLoadingAllDirectChats}
                                    outlined
                                    sx={{
                                        width: {xs: '100%', md: '120px'},
                                        height: '45px'
                                    }}
                                    textSx={{
                                        fontSize: {xs: 11, md: 14},
                                        textTransform: 'capitalize'
                                    }}
                                />
                            }
                        </Stack>
                    </Stack>
                </Grid>
            </Card>

            <DialogBox 
                title={openDialog.title}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {openDialog.message}
                    </Typography>
                }
                open={openDialog.open} 
                onClose={openDialog.close}
                onSuccess={openDialog.successCallback}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default ProfileSendInterestsCard;

ProfileSendInterestsCard.propType = {
    data: PropTypes.object,
    filterType: PropTypes.string,
    successCallback: PropTypes.func
}