import PropTypes from 'prop-types';

import { 
    Box,
    IconButton,
    Menu,
    Stack,
    Typography,
    Avatar,
    Divider,
    Tooltip
} from "@mui/material";

import CloseIcon from '../../assets/icons/close-icon';
import ButtonComponent from '../../components/form/button-component';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useGetAllNotificationsMutation, useReadNotificationsMutation } from '../../services/notification-api';
import SkeletonComponent from '../../components/loading/skeleton-component';
import APIErrorLayout from '../error/api-error/api-error-layout';
import EmptyLayout from '../error/empty/empty-layout';

const NotificationPanel = ({anchor, open, handleClose, clearNotificationCount}) => {
    const { t } = useTranslation();
    const notification_locale = "notification";

    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [notificationList, setNotificationList] = useState([]);

    const [
        getNotifications,
        {isLoading, isSuccess, data, isError, error}
    ] = useGetAllNotificationsMutation();

    const [
        readNotifications,
        {isSuccess: isSuccessReadNotification, isError: isErrorReadNotification, error: errorReadNotification}
    ] = useReadNotificationsMutation();

    useEffect(() => {
        if(isSuccess && data){
            console.log("Notification data...");
            console.log(data);

            setNotificationList([...notificationList, ...data.data.content]);
            setTotalPages(data.data.page.totalPages);

            if(activePage === 0) readNotifications();
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if(isSuccessReadNotification){
            console.log("Notification read success...");
            clearNotificationCount();
        }
        else if(isErrorReadNotification && errorReadNotification){
            console.log("Notification read error...");
            console.log(errorReadNotification);
        }
    }, [isSuccessReadNotification, isErrorReadNotification, errorReadNotification]);

    useEffect(() => {
        if(open){
            setActivePage(0);
        }
        else{
            setNotificationList([]);
        }
    }, [open]);

    useEffect(() => {
        if(open){
            getNotifications(activePage);
        }
    }, [activePage, open]);

    const capitalizeFirstLetter = useCallback((value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        if (bottom) {
            let page = activePage;
            page += 1;
            page = page > totalPages ? totalPages : page;

            setActivePage(page);
        }
    }

    const getNotificationBody = () => <>
        {
            notificationList.length > 0
            ?
            notificationList.map((item, index) => {
                return <div key={index} style={{width: '100%'}}>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='start'
                        spacing={2}
                        py={2}
                        pl={2}
                    >
                        <Avatar 
                            alt="Avatar"
                            src={item.additionalData?.image && item.additionalData.image.length > 0 ? item.additionalData.image[0] : ''}
                            sx={{ width: '75px', height: '75px' }}
                        />

                        <Stack
                            direction='column'
                            alignItems='start'
                            justifyContent='space-between'
                            spacing={1}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '14px'
                                }}
                            >
                                {item.additionalData?.name ? capitalizeFirstLetter(item.additionalData?.name) : 'Unknown sender'}
                            </Typography>

                            <Tooltip title={item.content}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}
                                >
                                    {item.content.length > 35 ? `${item.content.substring(0, 32)}...` : item.content}
                                </Typography>
                            </Tooltip>

                            {/* {
                                item.type === "REQUEST"
                                ?
                                <ButtonComponent 
                                    text={t(`${notification_locale}.view_request`)}
                                    onClick={() => {}}
                                    sx={{
                                        backgroundColor: 'rgba(251, 57, 87, 0.1)',
                                        width: '150px',
                                        height: '45px',
                                        boxShadow: 'none',
                                        '&:hover' : {
                                            backgroundColor: 'rgba(251, 57, 87, 0.1)',
                                            boxShadow: 'none',
                                        },
                                    }}
                                    textSx={{
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: theme.palette.primary.main,
                                        p: 0,
                                        textTransform: 'capitalize'
                                    }}
                                />
                                :
                                <></>
                            } */}
                        </Stack>
                    </Stack>

                    <Divider style={{
                        width:'100%', 
                        backgroundColor: 'rgba(217, 217, 217, 0.01)',
                        borderBottomWidth: 2,
                        opacity: 0.25
                    }} />
                </div>
            })
            :
            <EmptyLayout />
        }
    </>

    let body;

    if(isLoading){
        notificationList.length > 0
        ?
        body = getNotificationBody()
        :
        body = <Stack direction='column' spacing={2} my={1} alignItems='center' sx={{width: '100%'}}>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
            <SkeletonComponent variant='rounded' width='350px' height='75px'/>
        </Stack>
    } 
    else if (isError) {
        console.log(error);
        body = <APIErrorLayout error={error}/>
    }
    else if(isSuccess){
        body = getNotificationBody()
    }

    return(
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        onScroll: handleScroll
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: { p: 0 }
                }}
            >
                <Stack 
                    direction='column'
                    sx={{width: '400px'}}
                >
                    <Box
                        py={1.5}
                        px={2}
                        sx={{
                            backgroundColor: '#F6F6F6',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        }}
                    >
                        <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}
                            >
                                {t(`${notification_locale}.heading`)}
                            </Typography>

                            <IconButton aria-label="close" size="medium" sx={{backgroundColor: 'white'}} onClick={handleClose}>
                                <CloseIcon style={{width: 15, height: 15, color: 'black'}}/>
                            </IconButton>
                        </Stack>
                    </Box>

                    <Stack 
                        direction='column' 
                        alignItems='start'
                        py={2}
                        sx={{
                            height: '400px'
                        }}
                    >
                        {body}
                    </Stack>
                </Stack>
            </Menu>
        </>
    );
}

export default NotificationPanel;

NotificationPanel.propType = {
    anchor: PropTypes.object, 
    open: PropTypes.bool, 
    handleClose: PropTypes.func,
    clearNotificationCount: PropTypes.func
}