import { Helmet } from "react-helmet";
import ProfileMatchLayout from '../../sections/profile-general/profile-match/profile-match-layout';

const ProfileMatchPage = () => {
    return(
        <>
            <Helmet>
                <title>Profile Matches</title>
            </Helmet>

            <ProfileMatchLayout />
        </>
    );
}

export default ProfileMatchPage;