import { Helmet } from "react-helmet";
import ResetPasswordLayout from '../../sections/auth/reset-password/reset-password-layout';

const ResetPasswordPage = () => {
    return(
        <>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>

            <ResetPasswordLayout />
        </>
    );
}

export default ResetPasswordPage;