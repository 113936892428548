import { Helmet } from "react-helmet";

import DeleteProfileLayout from '../../sections/profile-settings/delete/delete-profile-layout';

const DeleteProfilePage = () => {
    return(
        <>
            <Helmet>
                <title>Delete Profile</title>
            </Helmet>

            <DeleteProfileLayout />
        </>
    );
}

export default DeleteProfilePage;