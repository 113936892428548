import { Helmet } from "react-helmet";
import PartnerPreferenceLayout from "../../sections/partner-preference/preference/preference-layout";
import { Outlet } from "react-router-dom";

const PartnerPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner Preference</title>
            </Helmet>

            <PartnerPreferenceLayout />
        </>
    );
}

export default PartnerPreferencePage;