import { Helmet } from "react-helmet";
import ProfileSearchLayout from '../../sections/profile-general/profile-search/profile-search-layout';

const ProfileSearchPage = () => {
    return(
        <>
            <Helmet>
                <title>Profile Search</title>
            </Helmet>

            <ProfileSearchLayout />
        </>
    );
}

export default ProfileSearchPage;