import { Helmet } from "react-helmet";

import DeactivateProfileLayout from '../../sections/profile-settings/deactivate/deactivate-profile-layout';

const DeactivateProfilePage = () => {
    return(
        <>
            <Helmet>
                <title>Deactivate Profile</title>
            </Helmet>

            <DeactivateProfileLayout />
        </>
    );
}

export default DeactivateProfilePage;