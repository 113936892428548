import { Helmet } from "react-helmet";
import SignupLayout from "../../sections/auth/signup/signup-layout";

const SignupPage = () => {
    return(
        <>
            <Helmet>
                <title>Signup</title>
            </Helmet>

            <SignupLayout />
        </>
    );
}

export default SignupPage;