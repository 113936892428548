import { Helmet } from "react-helmet";
import LoginLayout from "../../sections/auth/login/login-layout";

const LoginPage = () => {
    return(
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>

            <LoginLayout />
        </>
    );
}

export default LoginPage;