import { Helmet } from "react-helmet";
import ProfileDailyRecommendationsLayout from "../../sections/profile-general/profile-daily-recommendations/profile-daily-recommendations-layout";

const ProfileDailyRecommendationPage = () => {
    return(
        <>
            <Helmet>
                <title>Daily Recommendations</title>
            </Helmet>

            <ProfileDailyRecommendationsLayout />
        </>
    );
}

export default ProfileDailyRecommendationPage;