import { Helmet } from "react-helmet";
import BlockedProfileLayout from "../../sections/profile-settings/blocked/blocked-profile-layout";

const BlockedProfilesPage = () => {
    return(
        <>
            <Helmet>
                <title>Blocked Profiles</title>
            </Helmet>

            <BlockedProfileLayout />
        </>
    );
}

export default BlockedProfilesPage;